:root {
  --bkg-clr: rgb(32, 32, 32);
  --card-bkg-clr: rgb(21, 21, 21);
  --txt-clr: rgb(208, 208, 208);
  --trim-clr: rgb(21, 21, 21);
  --btn-clr: rgb(100, 100, 100);
  --btn-hover-clr: rgb(158, 158, 158);
  --txt-hover-clr: rgb(0, 0, 0);
  --alt-border-clr: rgb(24, 24, 24);
  --alt-shadow-clr: rgb(119, 82, 183);
}


body {
  background-color: var(--bkg-clr);
  font-family: "Rubik", sans-serif;
  color: var(--txt-clr);
  position: relative;
  min-height: 100vh;
}

.navbar {
  padding: 2rem;
  background-color: var(--trim-clr);
}
.navbar-brand {
  font-size: 36px;
  color: white;
}
@media only screen and (max-width: 600px) {
  .navbar-brand {
    font-size: 16px;
    color: white;
  }
}

.lang-btn {
  padding: .5rem;
  width: fit-content;
  margin-top: .5rem;
  background-color: var(--trim-clr);
  color: white;
  border: none;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.lang-btn:hover {
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
}

.lang-img {
  height: 20px;
}

.intro {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
}

.title {
  margin: 4rem;
  text-align: center;
  color: var(--txt-clr);
}

.card {
  height: 100%;
  background-color: var(--bkg-clr);
  color: var(--txt-clr);
  /* box-shadow: 2px 4px 6px rgb(54, 54, 54); */
}

.card:hover {
  transition: 150ms;
  /* box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3); */
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.card h5 {
  font-size: 24px;
}

.card p {
  color: var(--txt-clr);
}

.item-container {
  padding: 1rem;
  border-bottom: 1px solid var(--txt-clr);
}

.flex-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.btn-sm {
  background-color: var(--btn-clr);
  color: white;
  margin: .25rem .25rem .25rem 0;
}

.btn-sm:hover {
  transition: 500ms;
  background-color: var(--btn-hover-clr);
  color: var(--txt-hover-clr);
}

.img-height {
  height: 100%;
}

.prof-img {
  width: 100%;
  border-radius: .25rem;
}

.email-link {
  font-weight: 600;
}
.card-header {
  background-color: var(--bkg-clr);
  color: white;
  border-bottom: 1px solid var(--txt-clr)
}

/* .title {
  margin: 2rem;
  text-align: center;
  color: var(--trim-clr);
} */

.email {
  font-weight: 700;

}

.main {
  padding-bottom: 5rem;
  height: 100%;
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 5rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blog-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.spinner {
  margin-top: 5rem; 
  text-align: center;
}

.spinner-border {
  font-size: 24px;
  color: var(--trim-clr);
}

.flash__card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2em;
}

.flash__sentence {
  font-weight: 600;
}

.flash__btn {
  margin: 0 .25em;
  border: none;
  background-color: transparent;
  font-size: 26px;
}

.flash__btn:hover {
  color: var(--alt-shadow-clr);
}

.flash__input {
  margin: 2em;
  padding: .5em;
  width: 400px;
  border: none;
  border-bottom: 1px solid var(--trim-clr);
  outline: none;
}

.wedding__form {
  display: flex;
  flex-direction: column;
}

.wedding__name {
  display: flex;
  flex-direction: row;
  gap: .5em;
}

.wedding__div {
  margin: 1em 0;
  display: flex;
  flex-direction: column;
}
.wedding__div select {
  padding: .25em;
  border: none;
  border-radius: 6px;
}
.wedding__div input {
  padding: .25em;
  border: none;
  border-radius: 6px;
}
.wedding__div textarea {
  height: 100px;
  padding: .25em;
  border: none;
  border-radius: 6px;
  resize: none;
}